html, body, #root, #root>div {
  height: 100%;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

.module {
  height: calc(100% - 56px);
}

.word-card {
  display: flex;
  border-radius: 10px 10px 0 0;
  min-height: 100px;
  min-width: 200px;
  background: #eee;
  align-items: center;
  justify-content: center;
  position: relative;
}

.bullet {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  position: absolute;
  top: -5px;
  right: -5px;
}

.bullet-red {
  background: red;
}

.bullet-yellow {
  background: yellow;
}

.bullet-green {
  background: green;
}

.progress-custom {
  height: 0.3rem;
}